import React, { Component } from 'react';
import Projectfull from "../../components/Projectfull";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import IpvfullImg from "../../images/projectsfull/ipvfull.jpg";
import {GatsbySeo} from "gatsby-plugin-next-seo";
import {Helmet} from "react-helmet";

class Projects extends Component {
  render() {
    return (
      <div className="scroll-content">
        <Helmet>
          <meta charSet="utf-8" />
          <title>IPV Diergeneeskunde</title>
        </Helmet>
        <GatsbySeo noindex={true} />
        <div className="page project">
          <Header />
          <Projectfull
              projectfullname="IPV Diergeneeskunde"
              projectfulldescription="IPV is a Drupal commerce website where soon to be veterinarians and established veterinarians are able to buy supplementary courses to further advance their education. I designed and developed the front-end for this website."
              projectclient="UGent"
              projectrole="Web Design & Front-end Development"
              projectdate="2019 - 2020"
              projectfullimg={<div className="project--img--inner"><img data-src={IpvfullImg} className="project--img lazyload" alt="IPV Diergeneeskunde" /></div>}
              projectnextlink="/projects/bdmyshopi"
          />
          <Footer />
        </div>
      </div>
    );
  }
}

export default Projects;